import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './i18n';

const el = document.getElementById("root");

const root = ReactDOM.createRoot(el);

root.render(<App />)
