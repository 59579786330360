import React from 'react';

export default function ContactCard({ content }) {
  return (
    <div className='flex flex-col mx-auto justify-center text-center bg-secondary rounded-2xl text-sm lg:text-base xl:h-[650px] lg:h-[550px]'>
      <img
        src={content.image}
        className='transform -scale-x-100 hidden lg:block absolute left-0 h-[550px] z-1'
        alt='CurrImage'
      ></img>
      <img
        src={content.image}
        className='hidden lg:block absolute right-0 h-[550px] z-1'
        alt='CurrImage'
      ></img>
      <h4 className='text-primary mx-auto mt-4 px-4 text-center text-2xl'>
        {content.title}
      </h4>
      <span className='mx-auto sm:max-w-[300px] max-w-[200px] my-3 text-lg'>
        {content.description}
      </span>
    </div>
  );
}
