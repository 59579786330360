import React from "react";
import { useTranslation } from 'react-i18next';
import ContactCard from "./components/ContactCard";
import { IoIosMail } from "react-icons/io";
import karli from "../../images/karli.png";
import Button from "../../components/Button";

export default function Contacts() {
  const { t } = useTranslation();
  const info = [
    {
      title: t('we-are-active'),
      description: (
        <div className="flex flex-col space-y-3 text-xs sm:text-lg">
          <p className="text-sm">
            {t('write-us')}
          </p>
          <p className="text-sm">
            <a href="mailto:infoal@holbertonschool.com">
              <IoIosMail style={{ display: "inline"}} />
              infoal@holbertonschool.com
            </a>
          </p>
          <a
            href="https://al.linkedin.com/company/holberton-school-albania"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              label={t('our-profile-link')}
              type="primary"
              className="h-10 sm:w-[310px] pt-1"
            ></Button>
          </a>
          <a
            href="https://www.facebook.com/holbertonalb/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              label={t('our-profile-fcb')}
              type="primary"
              className="h-10 sm:w-[310px] pt-1"
            ></Button>
          </a>
          <a
            href="https://www.instagram.com/holberton.albania/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              label={t('our-profile-ig')}
              type="primary"
              className="h-10 sm:w-[310px] pt-1"
            ></Button>
          </a>
          <a
            href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1LRLT6cdAduWbvRQySvG7TVEjf9Pqtn1Oh-tqDdv4S7lGaS7JOBwJW5W7jyjamgsAuhOmGWi7V?gv=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              label={t('book-appointment')}
              type="primary"
              className="h-10 sm:w-[310px] pt-1"
            ></Button>
          </a>
        </div>
      ),
      image: karli,
    },
  ];
  return (
    <section id="contacts">
      <div className="items-center">
        <ContactCard content={info[0]} />
      </div>
    </section>
  );
}
