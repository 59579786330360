import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Kristi from "./images/vlora.jpeg";
import frensi_meta from "./images/frensi.png";
import akil_kabellari from "./images/akil_kabellari.png";
import evis_topollaj from "./images/evis_topollaj.png";
import fabio_hysollari from "./images/fabio_hysollari.png";
import sindi_hamataj from "./images/sindi_hamataj.png";
import romeo_llangozi from "./images/romeo_llangozi.png";
import ilvian_dimco from "./images/ilvian_dimco.png";
import ergi_lama from "./images/ergi_lama.png";
import arenc_palluqi from "./images/arenc_palluqi.jpg";
import izabela_nelaj from "./images/izabela_nelaj.png";
import arlind_hasimi from "./images/arlind_hasimi.png";
import akilrajdho from "./images/akilrajdho.png";
import aleksander from "./images/aleksander.png";
import besnikleskaj from "./images/besnikleskaj.png";



function useGetTestimonials(type) {
  const { t } = useTranslation();

  const personTestimonials = [
    {
      name: "Akil Rajdho",
      position: "CEO RPAy",
      quote: t('akil-rajdho'),
      photo: akilrajdho,
    },
    {
      name: "Aleksandër Risilia",
      position: "Co-Managing Director & CTO",
      quote: t('aleksander'),
      photo: aleksander,
    },
    {
      name: "Besnik Leskaj",
      position: "CEO Abkons",
      quote: t('besnik'),
      photo: besnikleskaj,
    },
    {
      name: "Kristi Seraj",
      position: "Holberton Alumni",
      quote: t('kristi_seraj'),
      photo: Kristi,
    },
    {
      name: "Frensi Meta",
      position: "Holberton Alumni",
      quote: t('frensi_meta'),
      photo: frensi_meta,
    },
    {
      name: "Sindi Hamataj",
      position: "Holberton Alumni",
      quote: t('sindi_hamataj'),
      photo: sindi_hamataj,
    },
  ];

  const studentTestimonials = [
    {
      name: "Izabela Nelaj",
      position: "Holberton Alumni",
      quote: t('izabela_nelaj'),
      photo: izabela_nelaj,
    },
    {
      name: "Akil Kabellari",
      position: "Holberton Alumni",
      quote: t('akil_kabellari'),
      photo: akil_kabellari,
    },
    {
      name: "Frensi Meta",
      position: "Holberton Alumni",
      quote: t('frensi_meta'),
      photo: frensi_meta,
    },
    {
      name: "Evis Topollaj",
      position: "Holberton Alumni",
      quote: t('evis_topollaj'),
      photo: evis_topollaj,
    },
    {
      name: "Ilvian Dimco",
      position: "Holberton Alumni",
      quote: t('ilvian_dimco'),
      photo: ilvian_dimco,
    },
    {
      name: "Fabio Hysollari",
      position: "Holberton Alumni",
      quote: t('fabio_hysenllari'),
      photo: fabio_hysollari,
    },
    {
      name: "Romeo Llangozi",
      position: "Holberton Alumni",
      quote: t('romeo_llangozi'),
      photo: romeo_llangozi,
    },
    {
      name: "Sindi Hamataj",
      position: "Holberton Alumni",
      quote: t('sindi_hamataj'),
      photo: sindi_hamataj,
    },
    {
      name: "Ergi Lama",
      position: "Holberton Alumni",
      quote: t('ergi_lama'),
      photo: ergi_lama,
    },
    {
      name: "Arenc Palluqi",
      position: "Holberton Alumni",
      quote: t('arence_palluqi'),
      photo: arenc_palluqi,
    },
    {
      name: "Arlind Hasimi",
      position: "Holberton Alumni",
      quote: t('arlind_hasimi'),
      photo: arlind_hasimi,
    },
    {
      name: "Kristjan Seraj",
      position: "Holberton Alumni",
      quote: t('kristi_seraj'),
      photo: Kristi,
    },
  ];
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    setTestimonials(
      type === "student" ? studentTestimonials : personTestimonials
    );
  }, [type]);

  return testimonials;
}

export default useGetTestimonials;
