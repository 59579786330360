import React from "react";

function TestimonialCard({ person, student }) {
  return (
    <div className="border bg-white py-5 px-5 mb-4 rounded-lg drop-shadow-lg">
      <div className="flex flex-col items-center">
        <img
          src={person.photo}
          className="rounded-full object-contain h-[85px] w-[85px] lg:h-[175px] lg:w-[175px] xl:h-[180px] xl:w-[180px] p-3 testimonial-img"
          alt="TestimonalImage"
        />
        <div className="flex flex-col max-w-xs xl:max-w-md pl-5 p-3">
          <p className="testimonial-quote">{person.quote}</p>
          <p className="text-primary mt-5 testimonial-info">
            {person.name}, {person.position}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TestimonialCard;
