import React from 'react';
import Button from '../../../components/Button';

export default function CurriculumCard({ content }) {
  return (
    <div className='container mx-auto flex flex-col justify-center text-center bg-secondary rounded-2xl text-sm lg:text-base xl:h-[650px] lg:h-[550px]'>
      <img src={content.image} className='rounded-t-2xl' alt='CurrImage'></img>
      <h4 className='text-primary mx-auto mt-4 px-4 text-center'>
        {content.title}
      </h4>
      <span className='mx-auto sm:max-w-[300px] max-w-[200px] my-3'>
        {content.description}
      </span>
      <h4 className='text-primary mx-auto my-auto sm:max-w-[300px] max-w-[200px]'>
        {content.subjects}
      </h4>
      <a href="/programet" target="blank">
        <Button label='Më shumë' className=' mx-auto mt-4 mb-6 ' />
      </a>
    </div>
  );
}
