import React from "react";
import { useTranslation } from 'react-i18next';
import Carousel from "react-multi-carousel";
import TestimonialCard from "../home/components/TestimonialCard";
import useGetTestimonials from "../../useGetTestimonials";
import "./index.css"

export default function Students() {
  const { t } = useTranslation();

  const students = useGetTestimonials("student");

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1440 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1439, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="flex flex-col justify-center mt-16">
      <div className="flex flex-col text-center space-y-8 " id="text">
        <h2 className="text-primary text-5xl std-text" style={{ fontWeight: "bold" }}>{t('our-students')}</h2>
      </div>
      <div className="flex flex-col pt-12 text-center text-xl px-32 pb-8 std-paragraph">
        {t('our-community')}
      </div>
      <div className="flex flex-col space-y-10">
        <h3 className="mx-auto text-5xl py-5">Testimonials</h3>
        <Carousel
          swipeable={true}
          draggable={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          containerClass="carousel-container"
          partialVisible={true}
          focusOnSelect={true}
          itemClass="lg:px-10 px-[35px]"
          autoPlay={true}
          autoPlaySpeed={4000}
        >
          {students.map((person, index) => {
            return (
              <div>
                <TestimonialCard person={person} key={index} student />
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
