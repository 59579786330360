import React from 'react';
import { useTranslation } from 'react-i18next';
import ar_vr from "../../images/ar_vr.png";
import fullstack from "../../images/fullstack.png";
import frontend from "../../images/frontend.png";
import backend from "../../images/backend.png";
import machine_learning_program from "../../images/machine_learning_program.png";
import low_level_algorithm from "../../images/low_level_algorithm.png";
import { useEffect, useState } from "react";
import ProgramCard from "./components/ProgramCard";
import { BsStack } from "react-icons/bs";
import { AiOutlineDownload } from "react-icons/ai";
import { BsStackOverflow } from "react-icons/bs";
import "./index.css";
function Programs() {
  const { t } = useTranslation();
  const info = [
    {
      title: t('machine-learning-program'),
      content: (
        <div>
          <h2 className="text-xl">
            {t('ml-prog-desc')}
          </h2>
          <p>
            <ul>
              <li>
                <span style={{ fontSize: "18px" }}><BsStack style={{ display: "inline" }} /> {t('tech')}:</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}> TensorFlow, Pandas, Keras, MongoDB</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Numpy, Matplotlib, Algorithms, Data Structures</span>
              </li>
              <li>
                <span style={{ fontSize: "18px", paddingRight: "5px" }}><BsStackOverflow style={{ display: "inline" }} /> {t('projects')}</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Object Detection, Facial recognition,</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Chatbot, Stock predictions</span>
              </li>
              <li className="curicula-btn">
                <a href="https://uploads-ssl.webflow.com/61055a196b2e2f7d69b9f5b5/6164102d9514763c45b27511_2021%20Albania%20-%20Foundations%20of%20Computer%20Science%20and%20Machine%20Learning.pdf" target="blank">
                  <button className='rounded-full py-2 bg-primary text-white px-8'> {t('curricula')} <AiOutlineDownload style={{ display: "inline" }} /></button>
                </a>
              </li>
            </ul>
          </p>
        </div>
      ),
      image: machine_learning_program
    },
    {
      title: t('ar-vr'),
      content: (
        <div>
          <h2 className="text-xl">
            {t('ar-vr-desc')}
          </h2>
          <p>
            <ul>
              <li>
                <span style={{ fontSize: "18px" }}><BsStack style={{ display: "inline" }} />{t('tech')}</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Unity3D, ARKit, C#, Unity AR Foundation,</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}> ARCore, ShaderGraph, Algorithms, Data Structures</span>
              </li>
              <li>
                <span style={{ fontSize: "18px", paddingRight: "5px" }}><BsStackOverflow style={{ display: "inline" }} />{t('projects')} </span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Build a VR game & 360 video, </span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Seated/standing VR experience, </span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Marker-based recognition</span>
              </li>
              <li className="curicula-btn">
                <a href="https://uploads-ssl.webflow.com/61055a196b2e2f7d69b9f5b5/61641030caa87895504f4862_2021%20Albania%20-%20Foundations%20of%20Computer%20Science%20and%20Augmented%20Reality%20_%20Virtual%20Reality.pdf" target="blank">
                  <button className='rounded-full py-2 bg-primary text-white px-8'> {t('curricula')}  <AiOutlineDownload style={{ display: "inline" }} /></button>
                </a>
              </li>
            </ul>
          </p>
        </div>
      ),
      image: ar_vr,
    },
    {
      title: t('full-stack'),
      content: (
        <div>
          <h2 className="text-xl">
            {t('full-stack-desc')}
          </h2>
          <p>
            <ul>
              <li>
                <span style={{ fontSize: "18px" }}><BsStack style={{ display: "inline" }} />{t('tech')}</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Modern Javascript, React / Redux, Node.js,</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Redis, Data privacy, Data structures, Algorithms, MySQL / MongoDB</span>
              </li>
              <li>
                <span style={{ fontSize: "18px", paddingRight: "5px" }}><BsStackOverflow style={{ display: "inline" }} /> {t('projects')}</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Desktop and mobile version of websites, MySQL performance debugging,</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}> Cache from scratch, Authentication service, Background jobs system,</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}> Student dashboard in React, CRM dashboard in React</span>
              </li>
              <li className="curicula-btn">
                <a href="https://uploads-ssl.webflow.com/61055a196b2e2f7d69b9f5b5/61641028dc629785d786e4db_2021%20Albania%20-%20Foundations%20of%20Computer%20Science%20and%20Full-Stack%20Web%20Development.pdf" target="blank">
                  <button className='rounded-full py-2 bg-primary text-white px-8'> {t('curricula')} <AiOutlineDownload style={{ display: "inline" }} /></button>
                </a>
              </li>
            </ul>
          </p>
        </div>
      ),
      image: fullstack,
    },
    {
      title: t('low-level-algorithm'),
      content: (
        <div>
          <h2 className="text-xl">
            {t('full-stack-alg-desc')}
          </h2>
          <p>
            <ul>
              <li>
                <span style={{ fontSize: "18px" }}><BsStack style={{ display: "inline" }} />{t('tech')} </span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>C, Shell, Linux, Multithreading, Assembly,</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Sockets, Algorithms & Data Structures</span>
              </li>
              <li>
                <span style={{ fontSize: "18px", paddingRight: "5px" }}><BsStackOverflow style={{ display: "inline" }} />{t('projects')} </span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Create your own advanced Shell and ls program, Create your own Malloc, </span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Build your own web server in C, Advanced algorithm design, Blockchain implementation in C</span>
              </li>
              <li className="curicula-btn">
                <a href="https://uploads-ssl.webflow.com/61055a196b2e2f7d69b9f5b5/6164102a7772381b3e8472db_2021%20Albania%20-%20Foundations%20of%20Computer%20Science%20and%20Low%20Level%20_%20Algorithms.pdf" target="blank">
                  <button className='rounded-full py-2 bg-primary text-white px-8'> {t('curricula')} <AiOutlineDownload style={{ display: "inline" }} /></button>
                </a>
              </li>
            </ul>
          </p>
        </div>
      ),
      image: low_level_algorithm,
    },
    {
      title: t('frontend'),
      content: (
        <div>
          <h2 className="text-xl">
            {t('frontend-desc')}
          </h2>
          <p>
            <ul>
              <li>
                <span style={{ fontSize: "18px" }}><BsStack style={{ display: "inline" }} />{t('tech')}</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>HTML/CSS, Redux, ES6, GraphQL,</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}> Responsive Design, Data Structures, React, Algorithms</span>
              </li>
              <li>
                <span style={{ fontSize: "18px", paddingRight: "5px" }}><BsStackOverflow style={{ display: "inline" }} />{t('projects')}</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Desktop and mobile version of a product website, Student dashboard in React, </span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>Countries portal with React and GraphQL, CRM dashboard in React </span>
              </li>
              <li className="curicula-btn">
                <a href="https://uploads-ssl.webflow.com/61055a196b2e2f7d69b9f5b5/61641034dc6297a56386e547_2021%20Albania%20-%20Foundations%20of%20Computer%20Science%20and%20Front-End%20Web%20Development.pdf" target="blank">
                  <button className='rounded-full py-2 bg-primary text-white px-8'> {t('curricula')} <AiOutlineDownload style={{ display: "inline" }} /></button>
                </a>
              </li>
            </ul>
          </p >
        </div >
      ),
      image: frontend,
    },
    {
      title: t('backend'),
      content: (
        <div>
          <h2 className="text-xl">
            {t('backend-desc')}
          </h2>
          <p>
            <ul>
              <li>
                <span style={{ fontSize: "18px" }}><BsStack style={{ display: "inline" }} />{t('tech')}</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>MySQL, NodeJS, Redis, Authentication,</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}> Caching, GraphQL, i18n, Data Structures</span>
              </li>
              <li>
                <span style={{ fontSize: "18px", paddingRight: "5px" }}><BsStackOverflow style={{ display: "inline" }} />{t('projects')} </span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}>MySQL performance debugging, Cache from scratch, Authentication service,</span>
                <span style={{ fontSize: "15px", fontWeight: "bold" }}> Yellow pages in GraphQL, Thumbnails on-demand</span>
              </li>
              <li className="curicula-btn">
                <a href="https://uploads-ssl.webflow.com/61055a196b2e2f7d69b9f5b5/61641035762e5d8047040666_2021%20Albania%20-%20Foundations%20of%20Computer%20Science%20and%20Back-End%20Web%20Development.pdf" target="blank">
                  <button className='rounded-full py-2 bg-primary text-white px-8'> {t('curricula')} <AiOutlineDownload style={{ display: "inline" }} /></button>
                </a>
              </li>
            </ul>
          </p >
        </div >
      ),
      image: backend,
    },
  ];
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderedCard = info.map((content, index) => {
    const isInView =
      window.innerHeight + scrollY > (index + 1) * 500 - 100 + index * 10;
    const animation = isInView
      ? index % 2 === 0
        ? "slide-left 1.0s ease-in-out"
        : "slide-right 1.0s ease-in-out"
      : "";
    return index % 2 === 0 ? (
      <ProgramCard
        key={index}
        title={content.title}
        content={content.content}
        image={content.image}
        style={{ animation }}
        icon={content.icon}
      />
    ) : (
      <ProgramCard
        key={index}
        title={content.title}
        content={content.content}
        image={content.image}
        style={{ animation }}
        icon={content.icon}
        reversed
      />
    );
  });

  return (
    <div className="flex flex-col justify-center mt-16">
      <div className="flex flex-col text-center space-y-8 " id="text">
        <div className="custom-styles">
          <h2 className="text-primary text-4xl" style={{ marginBottom: "10px", fontWeight: "bold" }}>{t('learn-technologies')}</h2>
          <span className='text-l lg:text-1xl font-light '>{t("hbt-offersProgram")}</span>
        </div>
        <h2 className="text-5xl programs-hbt-school" style={{ marginBottom: "10px", fontWeight: "bold" }}>{t('hbt-school-programs-study')}</h2>
        <span className='text-l lg:text-1xl font-light' style={{ width: "70%", margin: "0 auto" }}>
          {t('intensivePrograms')}
        </span>
      </div>
      <div className="flex flex-col pt-12 items-center pb-8 ">
        {renderedCard}
      </div>
    </div>
  );
}

export default Programs;
