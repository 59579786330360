import React from 'react';
import InfoButton from './InfoButton';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import Button from '../../../components/Button';
import { BiBookOpen } from 'react-icons/bi';
import { BiBot } from 'react-icons/bi';
import { BiBrain } from 'react-icons/bi';
import { BiBookmark } from 'react-icons/bi';



function Section() {

  useEffect(() => {

  }, []);

  const { t } = useTranslation();
  const contents = {
    learners: {
      title: t('forStudents'),
      description: t('std-school'),
      icon: <BiBookOpen className='my-auto mx-auto' />,
    },
    careers: {
      title: t('careerChangers'),
      description: t('careerProgram'),
      icon: <BiBot className='my-auto mx-auto' />,
    },
    parents: {
      title: t('forParents'),
      description: t('parents-desc'),
      icon: <BiBrain className='my-auto mx-auto' />,
    },
    business: {
      title: t('forBusiness'),
      description: t('business-desc'),
      icon: <BiBookmark className='my-auto mx-auto' />,
    },
  };
  const [content, setContent] = useState(contents.learners);


  const handleClick = (selectedContent) => {
    const element = document.getElementById("content")
    element.classList.remove('left');
    void element.offsetWidth;
    element.classList.add('left');
    setContent(selectedContent);
  };

  const typeClasses = (meth) => {
    return content === contents[meth]
      ? 'relative rounded-full  bg-primary text-white border-gray-300 border h-8 w-8 my-5 border-b-4 sm:h-[50px] sm:w-[310px] sm:my-1 sm:py-2 sm:px-7 sm:mr-5'
      : 'relative rounded-full  bg-white text-black border-primary border h-8 w-8 my-5 border-b-4 sm:h-[50px] sm:w-[310px] sm:my-1 sm:py-2 sm:px-7 sm:mr-5';
  };

  return (
    <div className='container mx-auto my-16 py-5 shadow-xl'>
      <div className='flex justify-center mb-16'>
        <h2 className='text-7xl text-center sm:text-start home-hbt-txt'>
          {t("forWhoIsHbtn")}
        </h2>
      </div>
      <div className='flex sm:flex-row sm:ml-24 ml-5 flex-col text-center'>
        <div className='flex flex-col space-y-5 sm:max-w-2xl justify-left mr-5 lg:mr-56'>
          <div id="content" className='left'>
            <h3 className='text-xl lg:text-3xl font-semibold'>
              {content.title}
            </h3>
            <span className='text-l lg:text-2xl font-light'>
              {content.description}
            </span>
          </div>

          <div className='pt-3'>
            <a href="https://apply.holbertonschool.com/" target="blank">
              <Button label={t('applyNow')} type='primary' />
            </a>
          </div>
        </div>
        <div className='flex flex-col space-y-5 max-w-xl items-center'></div>
        <div className='flex-col space-y-5 space-x-5 sm:flex sm:space-x-0'>
          <InfoButton
            label={t('forStudents')}
            onClick={() => handleClick(contents.learners)}
            classes={`${typeClasses('learners')}`}
            icon={contents.learners.icon}
          />
          <InfoButton
            label={t('careerChangers')}
            onClick={() => handleClick(contents.careers)}
            classes={`${typeClasses('careers')}`}
            icon={contents.careers.icon}
          />
          <InfoButton
            label={t('forParents')}
            onClick={() => handleClick(contents.parents)}
            classes={`${typeClasses('parents')}`}
            icon={contents.parents.icon}
          />
          <InfoButton
            label={t('forBusiness')}
            onClick={() => handleClick(contents.business)}
            classes={`${typeClasses('business')}`}
            icon={contents.business.icon}
          />
        </div>
      </div>
    </div>
  );
}

export default Section;
