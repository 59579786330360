import React from "react";
import { useTranslation } from 'react-i18next';
import "./partners.scss";

import sisal from "../../../images/sisal.png";
import logical from "../../../images/1.png";
import datamax from "../../../images/2.png";
import aess from "../../../images/3.png";
import primesoft from "../../../images/5.png";
import ubt from "../../../images/6.png";
import bkt from "../../../images/7.png";
import ritech from "../../../images/8.png";
import solution from "../../../images/9.png";
import ro from "../../../images/10.png";
import intesa from "../../../images/11.png";
import opt from "../../../images/12.png";
import vibtis from "../../../images/13.png";
import abkons from "../../../images/14.png";
import upt from "../../../images/15.png";
import barleti from "../../../images/16.png";
import smartwork from "../../../images/17.png";
import mext from "../../../images/18.png";
import baboon from "../../../images/19.png";
import sftoap from "../../../images/20.png";
import ikons from "../../../images/21.png";
import digitsapiens from "../../../images/22.png";
import oneup from "../../../images/23.png";
import easypay from "../../../images/24.png";
import balfin from "../../../images/25.png";



export default function Partners() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="bg-primary text-white">
        <div className="container mx-auto flex flex-col">
          <h3 className=" text-center mx-2 text-6xl font-bold mb-10">
            {t('hbt-partners')}
          </h3>
          <div className="flex flex-col spacy-y-6 mt-10 w-full">
            <div className="slider mb-2">
              <div className="slide-track flex flex-row">
                <div className="slide my-auto">
                  <img src={logical} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto ">
                  <img src={sisal} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto ">
                  <img src={intesa} height="150" width="250" alt="" />
                </div>

                <div clasName="slide my-auto ">
                  <img src={smartwork} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto ">
                  <img src={baboon} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto ">
                  <img src={mext} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto ">
                  <img src={aess} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto ">
                  <img src={oneup} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto ">
                  <img src={digitsapiens} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto ">
                  <img src={barleti} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto ">
                  <img src={sftoap} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto ">
                  <img src={ikons} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={easypay} height="150" width="250" alt="" />
                </div>

              </div>
            </div>
            <div className="slider-rev mt-26">
              <div className="slide-track flex flex-row">

                <div className="slide my-auto">
                  <img src={balfin} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={datamax} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={ubt} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={bkt} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={ritech} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={primesoft} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={solution} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={ro} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={opt} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={vibtis} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={abkons} height="150" width="250" alt="" />
                </div>

                <div className="slide my-auto">
                  <img src={upt} height="150" width="250" alt="" />
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url("https://uploads-ssl.webflow.com/6105315644a26f77912a1ada/610595c425bbb701519b65fe_wavesNegative.svg")`,
          rotate: "180deg",
          height: 100,
        }}
      ></div>
    </div>
  );
}
