import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Map() {
  const { t } = useTranslation();
  return (
    <div className='container mx-auto flex flex-col justify-cente'>
      <h3 className='mx-auto text-2xl my-7 py-5'>{t("hbt-openDay")}</h3>
      <div className='mx-auto mb-10' id='map'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.3761139269573!2d19.81690071528745!3d41.32243407927013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135031a14438dbd9%3A0x91773cbeec98eed7!2sHolberton%20School%20Albania!5e0!3m2!1sen!2s!4v1681124314749!5m2!1sen!2s'
          title='Map Holberton'
          style={{ borderRadius: '15px' }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
          className='w-[300px] h-[200px] sm:w-[600px] sm:h-[400px] lg:w-[850px] lg:h-[550px]'
        ></iframe>
      </div>
    </div>
  );
}
