import React from 'react';
import { useTranslation } from "react-i18next";
import Carousel from 'react-multi-carousel';
import { FaHeart } from "react-icons/fa";
import 'react-multi-carousel/lib/styles.css';
import CurriculumCard from './CurriculumCard.js';
import ar_vr_home from '../../../images/ar_vr_home.png';
import backend from '../../../images/backend_home.png';
import frontend_home from '../../../images/frontend_home.png';
import fullstack_home from '../../../images/fullstack_home.png';
import low_level from '../../../images/low_level_home.png';
import machine_learning from '../../../images/machine_learning_home.png';


export default function Curriculum() {
  const { t } = useTranslation();
  const info = [
    {
      title: t('machineLearning'),
      description: t('machineLearningDesc'),
      subjects: 'TensorFlow, Pandas, Keras ...',
      image: machine_learning,
      icon: <FaHeart />
    },
    {
      title: t('arVrSpc'),
      description: t('arVrSpcDsc'),
      subjects: 'Unity3D, ARKit, C#, Unity AR ...',
      image: ar_vr_home,
      icon: <FaHeart />
    },
    {
      title: t('fullStackSpc'),
      description: t('fullStackDsc'),
      subjects: 'Javascript moderne, React / Redux...',
      image: fullstack_home,
      icon: <FaHeart />
    },
    {
      title: t('lowLevelAlg'),
      description: t('lowLevelAlgSpc'),
      subjects: 'C, Shell, Linux, Multithreading ...',
      image: low_level,
      icon: <FaHeart />
    },
    {
      title: t('frontendSpc'),
      description: t('frontendSpcDesc'),
      subjects: 'HTML/CSS, Redux, ES6, GraphQL ...',
      image: frontend_home,
      icon: <FaHeart />
    },
    {
      title: t('backendSpc'),
      description: t('backendSpcDsc'),
      subjects: 'MySQL, NodeJS, Redis, Authentication ...',
      image: backend,
      icon: <FaHeart />
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 860 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 860, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      swipeable={true}
      draggable={true}
      responsive={responsive}
      ssr={true}
      infinite={true}
      containerClass='carousel-container'
      partialVisible={true}
      focusOnSelect={true}
      itemClass='lg:px-10 px-[35px]'
      autoPlay={true}
      autoPlaySpeed={5000}
    >
      <div>
        <CurriculumCard content={info[0]} />
      </div>
      <div>
        <CurriculumCard content={info[1]} />
      </div>
      <div>
        <CurriculumCard content={info[2]} />
      </div>
      <div>
        <CurriculumCard content={info[3]} />
      </div>
      <div>
        <CurriculumCard content={info[4]} />
      </div>
      <div>
        <CurriculumCard content={info[5]} />
      </div>
    </Carousel>
  );
}
