import { useEffect, useState } from "react";

import { useParams } from 'react-router-dom';
import events from "../Blog/events";
const SingleEvent = () => {
	const { id } = useParams();
	const [event, setEvent] = useState([]);

	useEffect(() => {
		fetchEvent();
	}, []);


	function fetchEvent() {
		const result = events.find((el, index) => {
			return el.id === id;
		});
		setEvent(result);
	}

	return (
		<div>
			<div class="mx-auto w-full max-w-screen-lg">
				<h2 style={{ fontSize: '25px' }}>{event.title}</h2>
				<h6>{event.date_time}</h6>
				{
					event.banner ?
						<img class="rounded-t-lg object-cover" src={event.banner} alt="event property" style={{ width: "100%", height: "450px" }} />
						:
						<img class="rounded-t-lg object-cover" src={event.image} alt="event property" style={{ width: "100%", height: "450px" }} />
				}

				{
					event.no_description ?
						""
						:
						<p className="text-1xl font-thin text-left pb-5" style={{ marginTop: "30px" }}>{event.description}</p>
				}


				{
					event.description_post ?
						event.description_post.map((el, index) => {
							return (
								<div key={index}>
									<h4 class="text-lg font-bold line-clamp-2">{el.title}</h4>
									<h5>{el?.date_time}</h5>
									<p class="my-4">
										{
											//console.log(el.description)
											el.description_extra?.map((el, index) => {
												return (
													<ul>
														<li>{el.title}</li>
													</ul>
												)
											})
										}

										{el.description}

										{
											el.recent_date === 27 ?
												<p style={{ marginTop: "10px" }}>
													For more details and to register for any of these workshops, please contact us at infoal@holbertonschool.com.
													<a href=" https://forms.gle/QDWSAWXcP2nRnEMe8" style={{ textDecoration: "underline" }}> Register at the link.</a>
													<br />
													We look forward to your participation!
												</p>
												: ""
										}

										{
											el.recent_date === 30 ?
												<p>To register,<a href={`${el.links}`} style={{ textDecoration: "underline" }}> click here</a> or contact us at +355697003060.
													Don't miss this incredible opportunity to dive into the world of machine learning and AI.
												</p>
												: ""
										}

										{
											el.recent_date === 16 ?
												<p>To register,<a href={`${el.links}`} style={{ textDecoration: "underline" }}> click here</a> or contact us at +355697003060.
													Don't miss this opportunity to learn, grow, and connect with fellow tech enthusiasts.
												</p>
												: ""
										}

										{
											el.link ?
												< b >
													<br />
													<a href={el.link} style={{ marginBottom: "30px" }}>Register here</a>
												</b>
												: ""
										}
									</p >
								</div>
							)
						})

						: ""
				}
			</div>
		</div>
	)
}

export default SingleEvent;