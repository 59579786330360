import React from 'react';
function ProgramCard({ title, content, image, reversed, style }) {
  if (reversed) {
    return (
      <div className="grid grid-cols-2 gap-10 my-16 programs-container-reverse" style={style}>
        <div className="col-span-1">
          <div className="flex flex-col max-w-md xl:max-w-md pl-8 h-full pt-5">
            <h2 className="text-xl text-primary font-semibold mobile-center">{title}</h2>
            <br />
            <p className="text-xl">{content}</p>
          </div>
        </div>
        <div className="col-span-1 img-program-container">
          <img
            src={image}
            alt="no img"
            className="h-[470px] w-[500px] border rounded-[30px]"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-10 my-16 programs-container" style={style}>
      <div className="col-span-1 img-program-container">
        <img
          src={image}
          alt="no img"
          className="h-[470px] w-[500px] border rounded-[30px]"
        />
      </div>
      <div className="col-span-1">
        <div className="flex flex-col max-w-md xl:max-w-md pl-8 h-full pt-5">
          <h2 className="text-xl text-primary font-semibold mobile-center">{title}</h2>
          <br />
          <p className="text-xl">{content}</p>
        </div>
      </div>
    </div>
  );
}

export default ProgramCard;
