import { Link } from 'react-router-dom';
import events from "./events";
import "./index.css";
const Blog = () => {
	return (
		<div class="mx-auto w-full max-w-screen-lg">
			{/* <h4 class="text-center text-3xl bg-primary py-2 rounded-lg text-white mt-6">Dive into Our Tech Blog</h4> */}
			<div class="grid grid-cols-3 gap-6 my-10" >
				{
					events.map((event, index) => {
						return (
							<div class="bg-white drop-shadow-md rounded-lg" key={index}>
								<img class="rounded-t-lg object-cover" src={event.image} alt="event property" style={{ width: "352px", height: "352px" }} />
								<div class="p-6">
									<h4 class="text-lg font-bold line-clamp-2">{event.title}</h4>
									<h6 class="font-bold text-xs">{event.date_time}</h6>
									<p class="my-4 line-clamp-6">{event.description}</p>
									<div class="text-end text-primary hover:text-red-800 font-bold">
										<Link to={`${event.id}`} >Read More</Link>
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
		</div >
	)
}

export default Blog;