import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

function InfoButton({ label, onClick, classes, icon }) {
  return (
    <button className={`${classes}`} onClick={onClick}>
      <div className='sm:flex'>
        <div className='flex flex-row justify-left'>
          {icon}
          <p className='pl-3 mt-1 hidden sm:block'>{label}</p>
        </div>
        <BsArrowRight className='absolute right-7 mt-2 invisible sm:visible' />
      </div>
    </button>
  );
}

export default InfoButton;
