
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import aboutUsHeader from "../../images/aboutUsHeader.jpg";
import whoIsHbt from "../../images/whoIsHbt.jpeg";
import ourStory from "../../images/ourStory.png";
import mission from "../../images/mission.jpg"


const AboutUs = () => {
	const { t } = useTranslation();
	return (
		<>
			<section className="min-h-[450px] py-7 bg-secondary">
				<div className="md:ml-20" style={{ marginTop: "20px", marginBottom: "20px" }}>
					<div className="my-3 py-4">
						<h4 className="sm:text-5xl text-3xl text-center text-primary sm:text-start md:text-5xl md:max-w-[700px]"></h4>
						<h1 className="sm:text-5xl text-3xl text-center sm:text-start md:text-8xl md:max-w-[1000px]">
							{t("educationBuild")}
						</h1>

						<span className="mt-4 pt-3 block text-2xl mb-2 text-primary text-center sm:text-start">
							{t('defineFuture')}
						</span>
					</div>
					<div className="flex flex-col sm:flex-row justify-center items-center sm:justify-start sm:items-start">
						<div className="my-2 sm:mx-2">
							<a href="https://apply.holbertonschool.com/" target="blank">
								<Button label={t('applyNow')} type="primary" />
							</a>
						</div>
						<div className="my-2 sm:mx-2">
							<a href="https://holbertonsite.vercel.app/programet" target="blank">
								<Button label={t('learnMore')} type="secondary" />
							</a>
						</div>
					</div>
				</div>

				<div
					className="hidden sm:block"
					style={{
						backgroundImage: `linear-gradient(to bottom, rgba(241, 242, 242, 0.42), rgba(225, 29, 63, 0.47)),url("${aboutUsHeader}")`,
						height: 600,
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
					}}
				></div>

				<div className="flex flex-col justify-center mt-16">
					<div className="flex flex-col pt-12 items-center pb-8 ">
						<div className="grid grid-cols-2 gap-10 my-16 method-container">
							<div className="col-span-1">
								<img
									src={whoIsHbt}
									alt="no img"
									className="border rounded-[30px]"
									width={"50%"}
									style={{ float: "right" }}
								/>
							</div>
							<div className="col-span-1">
								<div className="flex flex-col max-w-md xl:max-w-md pl-8 h-full pt-5">
									<h2 className="text-xl text-primary" style={{ fontSize: "35px" }}>{t("whoIsHbt")}</h2>
									<br />
									<p className="text-xl text-gray-700">{t("whoIsHbtText")}</p>
									<br />
									<p className="text-xl text-gray-700">{t("whoIsHbtTextOne")}</p>
									<br />
									<p className="text-xl text-gray-700">{t("whoIsHbtTextTwo")}</p>
								</div>
							</div>
						</div>
					</div>

					<div className="flex flex-col pt-12 items-center pb-8 ">
						<div className="grid grid-cols-2 gap-10 my-16 method-container">
							<div className="col-span-1">
								<div className="flex flex-col max-w-md xl:max-w-md pl-8 h-full pt-5" style={{ float: "right" }}>
									<h2 className="text-xl text-primary" style={{ fontSize: "35px" }}>{t("ourStory")}</h2>
									<br />
									<p className="text-xl text-gray-700">{t("ourStoryTextOne")}</p>

									<p className="text-xl text-gray-700" style={{ paddingTop: "10px" }}>{t("ourStoryTextThree")}</p>
								</div>
							</div>
							<div className="col-span-1">
								<img
									src={ourStory}
									alt="no img"
									className="border rounded-[30px]"
									width={"70%"}
								/>
							</div>
						</div>
					</div>


					<div className="flex flex-col pt-12 items-center pb-8 ">
						<div className="grid grid-cols-2 gap-10 my-16 method-container">
							<div className="col-span-1">
								<img
									src={mission}
									alt="no img"
									className="border rounded-[30px]"
									width={"85%"}
									style={{ float: "right" }}
								/>
							</div>
							<div className="col-span-1">
								<div className="flex flex-col max-w-md xl:max-w-md pl-8 h-full pt-5">
									<h2 className="text-xl text-primary" style={{ fontSize: "35px" }}>{t("hbtMission")}</h2>
									<br />
									<p className="text-xl text-gray-700">{t("missionTextOne")}</p>
									<br />
									<p className="text-xl text-gray-700">{t("missionTextTwo")}</p>
									<br />
									<p className="text-xl text-gray-700">{t("missionTextThree")}</p>
									<br />
									<p className="text-xl text-gray-700">{t("missionTextFowr")}</p>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
		</>
	)
}

export default AboutUs;