import blog_image from "../../images/new-blog-image.jpeg";
import fullstack_program from "../../images/fullstack-program.jpeg";
import german_amb from "../../images/german_ambasador.jpg";
import hbt_event from "../../images/hbt_event.png";
import career from "../../images/career.jpg";
import mayorTirana from "../../images/mayor_of_tirana.jpg";
import summer_school from "../../images/summer_school.jpeg";
import fullstack_summer from "../../images/fullstack_summer.jpeg";
import us_ambasador from "../../images/us_ambasador.jpg";
import hiring from "../../images/hiring.jpeg";
import hiring_one from "../../images/hiring_one.jpeg";
import workshop from "../../images/workshop.jpeg";
import vod_partner from "../../images/vod_partner.jpeg";

const events = [
	{
		id: "1",
		image: vod_partner,
		date_time: "31 July 2024",
		no_description: true,
		title: "Holberton Albania Partners with Vodafone Albania",
		description: "We are happy to share some fantastic news with our community: Holberton Albania has officially signed a Memorandum of Cooperation with Vodafone Albania! This partnership is much more than a formal agreement; it represents a shared vision and commitment",
		description_post: [
			{
				id: "1",
				description: "We are happy to share some fantastic news with our community: Holberton Albania has officially signed a Memorandum of Cooperation with Vodafone Albania! This partnership is much more than a formal agreement; it represents a shared vision and commitment to promote and empower the next generation of tech talent in Albania."
			},
			{
				id: "2",
				description: "At Holberton Albania, we believe in the power of innovative, project-based learning. Our approach is all about providing students with hands-on experience and the practical skills they need to excel in today’s fast-changing digital world. Vodafone Albania shares this vision, bringing its cutting-edge technology and expertise in talent acquisition to the table. "
			},
			{
				id: "3",
				description: "This partnership is a significant milestone for us. It is a crucial step forward in our mission to bridge the gap between education and industry. By teaming up with Vodafone Albania, we're not just enhancing the quality of education we provide, but we're also ensuring that our students have direct access to industry leaders and real-world experiences. Our students will benefit from real-world experience and professional development, preparing them to meet the demands of the modern job market with confidence. This collaboration also highlights the growing recognition and support for innovative educational models in Albania, paving the way for more collaborations and opportunities in the future."
			},
			{
				id: "4",
				description: "We want to extend a sincere thank you to Vodafone Albania that has supported this initiative. Stay tuned for more updates as we embark on this exciting journey together. "
			}
		]
	},
	{
		id: "2",
		image: workshop,
		title: "Upcoming Online Workshops at Holberton Albania",
		description: "Join us for a series of engaging and informative online workshops at Holberton Albania. These workshops are designed for current students, alumni, and anyone interested in Machine Learning, AI, and Web Development. Whether you are looking to enhance your skills, gain new insights, or explore the latest trends in technology, these workshops offer valuable opportunities to learn and grow. Our expert instructors will guide you through comprehensive sessions that blend theoretical knowledge with practical applications, ensuring you gain hands-on experience. Our expert instructors, Evis Plaku, Education Lead and Lector, and Andi Bevapi, Software Engineer and Mentor, will guide you through comprehensive sessions that blend theoretical knowledge with practical applications, ensuring you gain hands-on experience.  All sessions will be conducted online via Google Meet, providing you with the flexibility to participate from anywhere.",
		description_post: [
			{
				id: "1",
				title: "Introduction to Machine Learning and AI",
				description: "The workshop will start with a brief introduction to the objectives and Holberton School's mission. Participants will learn about the basic concepts of Machine Learning, including definitions, types, and real-world applications. This will be followed by an explanation of common Machine Learning algorithms with visual examples. An overview of Holberton's Machine Learning program, including curriculum and career support, will be provided. The session will conclude with an interactive Q&A and closing remarks.",
				date_time: "7 August 2024 11:00 AM"
			},
			{
				id: "2",
				title: "Building a Modern Web Application",
				description: "The workshop will begin with an introduction to the objectives and an overview of Holberton School. Participants will explore the fundamentals of modern web development, including front-end and back-end technologies and modern frameworks like React and Vue.js. The session will cover designing and building a responsive web page and provide details on Holberton's Web Development program. An interactive Q&A session will follow, concluding with closing remarks",
				date_time: "13 August 2024 11:00 AM"
			},
			{
				id: "3",
				title: "Building Your First Website from Scratch",
				recent_date: 27,
				description: "The workshop will start with an introduction to web development, covering front-end and back-end basics and essential tools. Participants will learn about HTML structure and tags, CSS styling, and adding interactivity with JavaScript. The session will also provide an overview of Holberton's Full-Stack Web Development program. An interactive Q&A will follow, ending with closing remarks.",
				date_time: "27 August 2024 11:00 AM"
			}
		]
	},
	{
		id: "3",
		image: hiring,
		banner: hiring_one,
		date_time: "12 July 2024",
		title: "Holberton School Albania is hiring.",
		description: "Job Title: Office and Admissions Assistant",
		description_post: [
			{
				id: "1",
				title: "About Holberton School Albania",
				description: "Founded in 2015 in Silicon Valley, Holberton School is dedicated to training the next generation of engineers. Our curriculum emphasizes hands-on learning through real-world projects, equipping students with practical skills for the tech industry. At Holberton Albania, we collaborate with leading IT companies to provide job opportunities, creating a supportive and industry-aligned educational environment",
			},
			{
				id: "2",
				title: "Position Overview",
				description: "We're on the lookout for an Office and Admissions Assistant to join our team starting 1 September 2024. In this role, you will be engaged in the admissions process and the daily administration of the office. If you have excellent organizational and communication skills and a passion for education, we'd love to meet you.",
			},
			{
				id: "3",
				title: "Key Responsibilities",
				description: "Office Assistance",
				description_extra: [
					{
						id: "0",
						title: "Support daily office operations, including managing documents and correspondence."
					},
					{
						id: "1",
						title: "Manage the staff's meeting and event calendar."
					},
					{
						id: "2",
						title: "Assist in organizing meetings and various activities within the office."
					},
					{
						id: "3",
						title: "Help communicate and coordinate with companies, educational institutions, and community organizations."
					}
				]
			},
			{
				id: "4",
				title: "Admissions",
				description_extra: [
					{
						id: "0",
						title: "Assist in the admissions process, from initial inquiry to enrollment."
					},
					{
						id: "1",
						title: "Guide prospective students through the application process."
					},
					{
						id: "2",
						title: "Help organize admissions events such as webinars, open houses, information sessions, and campus tours."
					},
					{
						id: "3",
						title: "Work closely with administrative and academic staff to align admissions with educational standards and goals."
					},
					{
						id: "4",
						title: "Maintain accurate records of admissions data and prepare regular reports on enrollment trends."
					}
				]
			},
			{
				id: "5",
				title: "Qualifications",
				description_extra: [
					{
						id: "0",
						title: "Bachelor’s degree in Business Administration, Communications, or a related field."
					},
					{
						id: "1",
						title: "At least 1 year of experience in administration or a related field, preferably in the education sector."
					},
					{
						id: "2",
						title: "Excellent written and verbal communication skills in English and Albanian."
					},
					{
						id: "3",
						title: "Ability to work independently and as part of a team in a dynamic environment."
					},
					{
						id: "4",
						title: "Proficiency in using administration and office management software and tools."
					}
				]
			},
			{
				id: "6",
				title: "Application Process",
				description: "If you're interested, please send your CV, cover letter, and  any relevant work samples to Ogerta Lala at ogerta.lala@holbertonschool.com by 31 July 2024. Use the subject line “Office and Admissions Assistant Application.”",
			},
			{
				id: "7",
				title: "",
				description: "* Holberton Albania is an equal opportunity employer.We celebrate diversity and are committed to creating an inclusive environment for all employees.Join us in shaping the future of tech education in Albania!"
			}
		]
	},
	{
		id: "4",
		image: fullstack_summer,
		date_time: "16 July 2024",
		title: "Join Holberton Albania's Free Summer School on Full Stack Web Development!",
		description: "Holberton Albania is excited to announce the Free Summer School in Full Stack Web Development! Building on the success of our Machine Learning Summer School, this new program is designed to empower recent high school graduates, students but also career changers, and aspiring web developers with essential skills.",
		description_post: [
			{
				id: "1",
				title: "Why Participate?",
				description_extra: [
					{
						id: "0",
						title: "Hands-on Learning: Dive into practical sessions covering HTML, CSS, JavaScript, Node.js, databases, and more."
					},
					{
						id: "1",
						title: "Expert Instructors: Learn from experienced professionals who are passionate about teaching."
					},
					{
						id: "2",
						title: "Collaborative Projects: Work in teams to build projects, fostering collaboration and networking."
					}
				]
			},
			{
				id: "2",
				title: "The Summer School will take place in two parts:",
				description_extra: [
					{
						id: "0",
						title: "Part I: Fundamentals of Web Development (HTML, CSS, Bootstrap)"
					},
					{
						id: "1",
						title: "Dates: July 9, 16, 23 (every Tuesday from 10:00 AM to 13:00 PM)"
					},
					{
						id: "2",
						title: "Part 2: Level Up your skills (JavaScript and React)"
					},
					{
						id: "3",
						title: "Dates: September 3, 10, 17 (every Tuesday, from 10:00 AM - 13:00 PM )"
					},
					{
						id: "4",
						title: "By the end of the program, participants will have a portfolio of projects showcasing their new skills."
					}
				]
			},
			{
				id: "7",
				title: "Registration Information",
				recent_date: 16,
				description: "With the start date fast approaching, it is time for interested participants to secure their spots. This program is free and open to all young learners interested in technology. Regular participants who complete the program will receive a certificate recognizing their dedication and the skills they have acquired.Don't miss this opportunity to learn, grow, and connect with fellow tech enthusiasts.",
				links: "https://forms.gle/EdkEVALmPwdtvSMj9"
			},
		]
	},
	{
		id: "5",
		image: us_ambasador,
		date_time: "10 June 2024",
		title: "Holberton Albania Welcomes U.S. Chargé d’Affaires David Wisner, Highlighting Impact on Local ICT Sector.",
		description: "Holberton School Albania, a Silicon Valley-born institution, was honored to host on 10 June 2024 the U.S. Chargé d’Affaires David Wisner showcasing its commitment to advancing Albania’s Information and Communications Technology (ICT) sector. During his visit, Chargé d’Affaires Wisner engaged in meaningful exchanges with both current students and alumni, discussing their projects, aspirations, and the transformative educational experience at Holberton.This interaction underscored the embassy’s interest in the direct impact of education on Albania’s tech industry development. Since its inception, Holberton has been instrumental in equipping students with the practical experience necessary to thrive in rapidly evolving business environments.Our school’s unique curriculum focuses on creating problem solvers and team players, skills in high demand in today’s tech industry.The visit provided an opportunity to demonstrate how Holberton’s cutting- edge educational strategies are directly contributing to the creation of higher - paying jobs. Holberton School Albania continues to push forward, preparing students to be pioneers in the ICT sector, and contributing to the country’s development.",
	},
	{
		id: "6",
		image: summer_school,
		date_time: "30 May 2024",
		title: "Countdown Begins for Holberton School Albania's Machine Learning Summer School!",
		description: "Holberton School Albania is excited to announce that its highly anticipated Machine Learning Summer School is just around the corner, set to kick off on June 11, 2024. This free, unique educational program is designed to immerse students, young professionals, and machine learning enthusiasts in the dynamic world of artificial intelligence through an intensive series of workshops and discussions led by industry experts.",
		description_post: [
			{
				id: "1",
				title: "Get Ready for an AI Adventure",
				description_extra: [
					{
						id: "0",
						title: "The Machine Learning Summer School spans five weeks, with sessions held every Tuesday from 10:00 to 15:00."
					},
					{
						id: "1",
						title: "Here's a sneak peek at what participants can look forward to:"
					}
				]
			},
			{
				id: "2",
				title: "Week 1: Introduction to Machine Learning",
				description_extra: [
					{
						id: "0",
						title: "Learn the basics of machine learning algorithms."
					},
					{
						id: "1",
						title: "Master essential Python programming skills."
					},
					{
						id: "2",
						title: "Engage in hands - on activities with simple ML algorithms using scikit- learn"
					}
				]
			},
			{
				id: "3",
				title: "Week 2: Data Processing and Visualization",
				description_extra: [
					{
						id: "0",
						title: "Explore data preprocessing techniques."
					},
					{
						id: "1",
						title: "Dive into data visualization using Matplotlib and Seaborn."
					},
					{
						id: "2",
						title: "Apply techniques to real- world datasets for practical insights."
					}
				]
			},
			{
				id: "4",
				title: "Week 3: Supervised Learning and Evaluation Metrics",
				description_extra: [
					{
						id: "0",
						title: "Understand supervised learning concepts and applications."
					},
					{
						id: "1",
						title: "Learn about accuracy, precision, recall, and F1- score evaluation metrics."
					},
					{
						id: "2",
						title: "Work on regression and classification algorithms and supervised learning projects."
					}
				]
			},
			{
				id: "5",
				title: "Week 5: Portfolio Project Presentation",
				description_extra: [
					{
						id: "0",
						title: "Develop and showcase a portfolio project demonstrating machine learning skills."
					},
					{
						id: "1",
						title: "Enhance presentation skills for effectively communicating project outcomes."
					},
					{
						id: "2",
						title: "Present projects to peers, mentors, and industry professionals."
					}
				]
			},
			{
				id: "6",
				title: "Esteemed Speakers and Industry Insights",
				description: "Participants will benefit from the expertise of Holberton Albania Education Lead and Machine Learning Researcher Mr Evis Plaku and other renowned speakers from major companies such as Lufthansa, Vodafone, and Ritech International AG."
			},
			{
				id: "7",
				title: "Registration Information",
				recent_date: 30,
				description: "With the start date fast approaching, it is time for interested participants to secure their spots. Regular participants who complete the program will receive a certificate recognizing their dedication and the skills they have acquired. ",
				links: "https://docs.google.com/forms/u/1/d/e/1FAIpQLSfvh71GhDX8QBIqZ8GBrE_raatf5t9MPIxNJ-Eeo16cJrKxCw/viewform?usp=send_form"
			},

		]
	},
	{
		id: "7",
		image: mayorTirana,
		date_time: "3 Maj 2024",
		title: "Mayor of Tirana Visits Holberton School Albania",
		description: "On 3 May 2024, Holberton School Albania was honored to host a visit from the Mayor of Tirana, Mr. Erion Veliaj. Holberton's students shared their personal experiences in a relaxed and open conversation, emphasizing how Holberton School has empowered them to take on challenging projects. They spoke about the collaborative environment at Holberton, where real-world applications and continuous peer support drive learning. Alumni also shared their professional journeys post- graduation stories, detailing how Holberton's rigorous curriculum and supportive network helped them secure positions at leading tech companies in Albania. Their contributions are advancing their careers and driving innovation within Tirana's burgeoning tech ecosystem. Holberton is dedicated to building bridges, fostering a strong sense of community, and inspiring young people to shape their city through technology.We're thrilled to share the Mayor's vision of making Tirana a vibrant, livable city for young people from Albania and worldwide!"
	},
	{
		id: "8",
		image: german_amb,
		date_time: "25 Prill 2024",
		title: "German Ambassador Karl Bergner visited Holberton Albania",
		description: "The German Ambassador to Albania, Karl Bergner, visited Holberton School Albania on April 25th, where he gained insight into our mission, programs, and collaborations with tech industry partners. Discussions with Holberton alumni currently employed by Ritech, Jurgen Vorfi, highlighted the integration of Holberton graduates into significant projects with companies like Siemens in Germany and the USA.Our current foundation students shared their diverse career transition experiences, emphasizing how Holberton has enhanced their technical and soft skills.Alumni currently in internships and entry- level roles showcased the competitive edge gained through our intensive, project - based learning approach, with over 100 projects in their portfolios. Ambassador Bergner's visit underscored Holberton Albania School's pivotal role in shaping tech talent and fostering global partnerships.Through collaborations with industry leaders, we continue to empower students to excel in the ever - evolving tech landscape, ensuring their readiness for impactful careers."
	},
	{
		id: "9",
		image: hbt_event,
		date_time: "25 Prill 2024",
		title: "Holberton School Albania and Shkolla e Mesme Profesionale TIK 'Hermann Gmeiner' among winners at Third National Grant Award Ceremony in Albania.",
		description: "We are thrilled to announce that Holberton School Albania, in collaboration with Shkolla e Mesme Profesionale TIK 'Hermann Gmeiner', has emerged as one of the winners of the grants from the Regional Challenge Fund (RCF). This prestigious recognition underscores our commitment to excellence in vocational education and training.The Regional Challenge Fund(RCF) is pivotal in fostering innovation and dedication within the vocational education sector.Through its Third Call, a total of 10 successful projects from Albania have been proposed for finance, with approximately 5.4 million Euros allocated for investments in equipment, infrastructure, and training at vocational training institutes implementing cooperative training.At Holberton School Albania, we are honored to be part of this transformative initiative.Our mission is to empower students with the skills and knowledge needed to excel in the rapidly evolving landscape of technology and industry.Through collaborative efforts with partnering enterprises and institutions like Shkolla e Mesme Profesionale TIK 'Hermann Gmeiner', we aim to provide students with access to cutting- edge training programs that cover key sectors such as ICT, Tourism, Energy, Construction, and Finance.Our consortium's success in securing RCF support reflects the dedication and passion of our team, as well as the invaluable support of our partners, BALFIN, As we look forward to implementing our project, we remain steadfast in our commitment to nurturing the talents and potential of our students. Together, let us embark on a journey of innovation, collaboration, and success at Holberton School Albania. Join us as we shape the future of vocational education in Albania and beyond."
	},
	{
		id: "10",
		image: career,
		date_time: "25 Prill 2024",
		title: "Holberton Albania Announces Opening of Applications for Foundation Program with Series of Informative Events",
		description: "Holberton School Albania is thrilled to announce the opening of applications for our esteemed Foundation program, marking an exciting opportunity for aspiring developers to embark on their journey into the world of software development.With a commitment to providing accessible and comprehensive education, we invite passionate individuals to apply and join our dynamic learning community.",
		description_post: [
			{
				id: "1",
				title: "Foundation Program Application Deadline: May 24th, 2024",
				desc: "To kickstart this exciting phase, we are hosting a series of informative events designed to provide prospective applicants with valuable insights into our program offerings and financial support opportunities."
			},
			{
				id: "2",
				title: "Online Webinar: Introduction to Foundation Program(May 8th, 2024)",
				desc: "On May 8th, join us for an exclusive online webinar where we will provide an in-depth introduction to our Foundation program.From exploring the program structure to diving into the curriculum and learning methodology, this webinar offers a comprehensive overview for individuals interested in pursuing a career in software development.Don't miss this opportunity to learn more about how Holberton Albania can help you achieve your goals",
				link: "https://www.google.com/url?sa=D&q=https://forms.gle/hf5xyj7yruy5RYfA6&ust=1714727760000000&usg=AOvVaw3XLhy84V6Rcwm6i4qiyFPI&hl=en&source=gmail",
			},
			{
				id: "3",
				title: "Open House Event: Foundation Program (May 13th, 2024)",
				desc: "Experience firsthand what it's like to be part of the Holberton community by attending our Open House event on May 13th.Get a glimpse into the learning environment, interact with current students and alumni, and gain valuable insights into the transformative journey that awaits you.Whether you are curious about the program or ready to take the next step, this event is the perfect opportunity to connect with us and explore the possibilities.",
				link: "https://docs.google.com/forms/d/e/1FAIpQLSfsixyOwg67rYSLa-v1dLo2EDIzYdcOaF1wyCIVa0UbshDNzQ/viewform"
			},
			{
				id: "4",
				title: "Online Webinar: Scholarships Provided by AKPA (May 19th, 2024)",
				desc: `As part of our commitment to accessibility, we are pleased to announce an online webinar focusing on the
						scholarships provided by the Agjencia Kombëtare e Punësimit dhe Aftësive on May 19th.Learn about the
						various scholarship opportunities available to support your education and gain valuable insights into the
						application process and eligibility criteria.Whether you're seeking financial assistance or want to learn
						more about available resources, this webinar is a must- attend event.
						At Holberton Albania, we believe in empowering individuals to pursue their passion for technology and
						unlock their full potential.With a rigorous curriculum, hands - on learning approach, and supportive
						community, our Foundation program will equip you with the skills and knowledge needed to thrive in todays digital landscape.
						Apply now for the Foundation program and take the first step towards a rewarding career in software
						development.The deadline for applications is May 24th, 2024.`,
				link: "https://docs.google.com/forms/d/e/1FAIpQLSesHJoSPTb865XVOWoB-SpV3kMhPfp_-heBa0k0JBjp8zkBBw/viewform"
			}
		]
	},
	{
		id: "11",
		image: fullstack_program,
		date_time: "16 Prill 2024",
		hours: "17:00 - 19:00",
		title: "Konkurs - Zhvillimi i webit për të fituar bursë studimi në Holberton Albania!",
		description: "A jeni i apasionuar pas webit? Dëshironi të studioni në një shkollë elitare me një kurrikulë nga Silicon Valley? Holberton Albania po organizon një konkurs për entuziastët e teknologjisë si ju!",
		extra_data: [{
			title: "Çfarë përfitoni pas konkursit?",
			description: ["6 muaj trajnim intensiv të Full Stack Web Development.", "Mësoni nga mentorët më të mirë.", "Bashkohuni me një rrjet global të profesionistëve të teknologjisë."],
			title_two: "Çmimet e Konkursit:",
			description_two: ["Fituesi: 30% bursë, shtesë mbi bursën nga AKPA.Të gjithë pjesëmarrësit: Fitoni aftësi dhe eksperiencë në zhvillimin e web-eve dhe mundësinë për tu bërë pjesë e Holberton.", "Kompeticioni është i hapur për të gjithë: Holberton beson në diversitet dhe gjithëpërfshirje. Konkurrenca është e hapur për të gjitha gjinitë, sfondet sociale, kulturore dhe etnike. Dëshirojmë të ofrojmë mundësine e të qënit programuesit më të mirë në treg, për të gjithë! Theksojmë se pjesëmarrja e vajzave dhe të rinjve që i përkasin kategorive të ndryshme sociale është prioritet."],
			title_three: "Formati i Konkursit:",
			description_three: ["16 prill: Sesione kodimi live në Linux, HTML, CSS dhe JavaScript me udhëzim nga ekspertet Punë e Pavarur: Aplikantët do të punojnë në projektet e tyre të zhvillimit të faqes së internetit në mënyrë të pavarur. 18 prill: Prezantimi i projektit tuaj të përfunduar për të treguar aftësitë tuaja!"],
			register_title: "Regjistrohuni ketu",
			register_link: "https://docs.google.com/forms/d/e/1FAIpQLSd9t6rhm1dZFkfuJKLqBRHVNbATND7PU-j1HB5W3LuFfMkr6g/viewform?pli=1",
			email_title: "Për më shume informacion kontaktoni në email:",
			email: "infoal@holbertonschool.com"
		}]
	},
	{
		id: "12",
		image: blog_image,
		date_time: "9 Prill 2024",
		hours: "15:00",
		title: "Webinar online 'Full Stack Web Development' nga Evis Plaku dhe Andi Bevapi!",
		description: "Të martën më datë 9 Prill 2024, ora 15:00 Holberton Albania ju fton në Webinarin me tematikë “Full Stack Web Development” me mentorët e Holberton Albania, Evis Plaku dhe Andi Bevapi. Në këtë event do të prezantohet programi i parë dedikuar part – time Full Stack Web Development nga Holberton Albania.Mentori Evis Plaku do t’iu prezantojë me programin inovativ që sjell ekspertizën e programuesve të Silicon Valley në një kurrikul ndërkombëtare për studentët tanë në Tiranë.Webinari do pasohet nga një sesion live- coding nga Andi, mentori i programit dhe specialist i Full Stack.Gjatë këtij sesioni do keni mundësi të njiheni me metodologjinë, programin dhe projektet duke zbatuar gjithashtu një projekt shumë interesant së bashku me ekspertët e kodimit.Ky event është i hapur për të apasionuarit pas teknologjisë, studentët e ardhshëm, studentët dhe alumnit e Holberton Albania dhe për të gjithë ata që duan të dinë pse Holberton është rruga drejt suksesit.",
		extra_data: [{
			register_title: "Regjistrohuni për të marrë pjesë",
			register_link: "https://docs.google.com/forms/d/e/1FAIpQLScsmPJQ77buofBWILUPrtkm1rhLHhabjXik4UI78QAN6lHZkQ/viewform",
			email_title: "Për më shume informacion kontaktoni në email:",
			email: "infoal@holbertonschool.com"
		}]
	},
]

export default events;