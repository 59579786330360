import React from 'react';
function MethodologyCard({ title, content, image, reversed, style }) {
  if (reversed) {
    return (
      <div className="grid grid-cols-2 gap-10 my-16 method-container-reverse" style={style}>
        <div className="col-span-1">
          <div className="flex flex-col max-w-md xl:max-w-md pl-8 h-full pt-5" style={{ marginLeft: "310px" }}>
            <h2 className="text-xl text-primary">{title}</h2>
            <br />
            <p className="text-xl">{content}</p>
          </div>
        </div>
        <div className="col-span-1">
          <img
            src={image}
            alt="no img"
            className="border rounded-[30px] image-revers"
            width={"80%"}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-10 my-16 method-container" style={style}>
      <div className="col-span-1">
        <img
          src={image}
          alt="no img"
          className="border rounded-[30px]"
          width={"80%"}
          style={{ float: "right" }}
        />
      </div>
      <div className="col-span-1">
        <div className="flex flex-col max-w-md xl:max-w-md pl-8 h-full pt-5">
          <h2 className="text-xl text-primary">{title}</h2>
          <br />
          <p className="text-xl">{content}</p>
        </div>
      </div>
    </div>
  );
}

export default MethodologyCard;
