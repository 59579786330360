import React from 'react';
import { useState } from "react";

function Question({ qa }) {
  const [sign, setSign] = useState(false);

  const handleOpen = () => {
    setSign(!sign);
  };

  const animation = {
    animation: "slide-small 1.0s ease-in-out",
  };

  return (
    <div className="max-w-[550px]">
      <div
        onClick={handleOpen}
        className="flex rounded bg-secondary border-gray-700 px-5 py-3 justify-between"
      >
        <p>{qa.question}</p>
        <button>{sign ? "-" : "+"}</button>
      </div>
      {sign ? (
        <p className="w-full p-3" style={animation}>
          {qa.answer}
        </p>
      ) : null}
    </div>
  );
}

export default Question;
