import React, { useState } from "react";
import "../styles/styles.css";
import { useTranslation } from 'react-i18next';
import grouplearning from "../../../images/grouplearning_phi.png";
import learnhowto from "../../../images/learnhowto_phi.png";
import projectbased from "../../../images/projectbased_phi.png";


export default function Methodology() {


  const { t } = useTranslation();
  const methods = {
    method1: {
      title: t("practic-learning"),
      text: t('practic-text'),
      image: learnhowto,
    },
    method2: {
      title: t('work-in-group'),
      text: t("workdGroupText"),
      image: grouplearning,
    },
    method3: {
      title: t('howToLearn'),
      text: t('howToLearnDesc'),
      image: projectbased,
    },
  };
  const [method, setMethod] = useState(methods.method1);






  const typeClasses = (meth) => {
    return method === methods[meth]
      ? "bg-primary text-white  rounded-full h-8 w-8 sm:h-6 sm:w-6"
      : "bg-white text-primary border border-primary rounded-full h-8 w-8 sm:h-6 sm:w-6";
  };

  const handleClick = (meth) => {
    setMethod(methods[meth]);
  };

  return (
    <div className="container flex flex-col items-center mx-auto space-y-5 box my-12 p-16 rounded-3xl">
      <h3 className="text-primary font-extrabold text-5xl my-5 py-auto text-center home-hbt-txt">
        {t('hbt-philozophy')}
      </h3>
      <h4 className="hidden sm:block text-2xl mb-3 text-center sm:text-center">
        {t('hbt-build')}
      </h4>
      <br />
      <br />
      <div
        id="method-card"
        className="flex flex-col sm:flex-row items-center container sm:justify-evenly card"
        key={method.title}
      >
        <div className="flex flex-col flex-wrap max-w-[500px] space-y-10 text-center sm:text-start home-first-slider">
          <h3 className="text-4xl">{method.title}</h3>
          <p className="text-2xl font-thin">{method.text}</p>
        </div>

        <img
          src={method.image}
          alt="vlora"
          className="drop-shadow-2xl object-contain w-80 h-120  rounded-3xl home-first-slider-img"
        />
      </div>
      <div id="Methods" className="flex flex-row space-x-6 sm:space-x-14">
        <div
          id="method1"
          className={`${typeClasses("method1")} my-5 border-b-4`}
          onClick={() => handleClick("method1")}
        >
          <button></button>
        </div>
        <div
          id="Method2"
          className={` ${typeClasses("method2")} my-5 border-b-4`}
          onClick={() => handleClick("method2")}
        >
          <button></button>
        </div>
        <div
          id="Method3"
          className={`${typeClasses("method3")} my-5 border-b-4`}
          onClick={() => handleClick("method3")}
        >
          <button></button>
        </div>
      </div>
    </div>
  );
}
