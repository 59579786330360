import React from "react";
import { useTranslation } from 'react-i18next';
import hlogo from "../images/logo.png";
import fb from "../images/fb.jpeg";
import twitter from "../images/twitter.png";
import insta from "../images/insta.webp";
import linkdin from "../images/Linkedin.png";
import Button from "./Button";

const FooterLinks = ({ label, link }) => {

	return (
		<a href={link} target="_blank" rel="noreferrer" className="px-6 font-semibold">
			{label}
		</a>
	);
};

export default function Footer() {
	const { t } = useTranslation();
	return (
		<footer className="flex flex-col">
			<div
				style={{
					backgroundImage: `url("https://uploads-ssl.webflow.com/6105315644a26f77912a1ada/610595c425bbb701519b65fe_wavesNegative.svg")`,
					height: 100,
				}}
			></div>
			<div className="bg-primary items-center sm:justify-center flex flex-col sm:flex-row sm:space-x-6 p-10">
				<h3 className="text-secondary text-1xl sm:text-5xl">
					{t('choose-hbt')}
				</h3>
				<a href="https://apply.holbertonschool.com/" target="blank">
					<Button type="secondary" label={t('applyNow')}></Button>
				</a>
			</div>
			<div className="md:flex flex-row container mx-auto h-48" id="Logos">
				<div
					className="container mx-auto my-5 flex flex-col sm:flex-row items-center"
					id="test"
				>
					<img
						src={hlogo}
						alt="Logo"
						style={{ height: 60 }}
						className="w-[200px] flex-grow-0"
					></img>

					<div className="flex flex-row w-full mr-15 space-x-4 mb-1 sm:justify-end justify-center my-3">
						<a href="https://www.facebook.com/holbertonalb/">
							<img
								src={fb}
								style={{ height: 40, width: 40 }}
								alt="fblogo"
							></img>
						</a>
						<a href="https://www.instagram.com/holberton.albania/">
							<img
								src={insta}
								style={{ height: 40, width: 40 }}
								alt="instalogo"
							></img>
						</a>
						<a href="https://twitter.com/holbertonalb?lang=en">
							<img
								src={twitter}
								style={{ height: 40, width: 40 }}
								alt="twitterlogo"
							></img>
						</a>
						<a href="https://al.linkedin.com/company/holberton-school-albania">
							<img
								src={linkdin}
								style={{ height: 40, width: 40 }}
								alt="linkdinlogo"
							></img>
						</a>
					</div>
				</div>
			</div>
			<div
				id="Links"
				className="flex flex-wrap w-full justify-around border-t border-b py-4"
			>
				<div className="flex flex-col items-center">
					<FooterLinks label={t('about-us')} link="/about-us" />
					<FooterLinks label={t('programs')} link={"/programet"} />
					<a href="https://breezy-sofa-f38.notion.site/50910fd3cfe243f28b483ecdf9402d88?v=625a23a1d52d43bda75492395deace4e" target="_blank" rel="noreferrer">
						<FooterLinks label={t('events')} />
					</a>
				</div>
				<div className="flex flex-col items-center">
					<FooterLinks label={t('methodology')} link={"metodologjia"} />
					<FooterLinks label={t("applicationAdmissions")} link={"https://apply.holbertonschool.com/"} />
					<FooterLinks label={t("ourStudents")} link={"/studentet"} />
				</div>
				<div className="flex flex-col items-center">
					<FooterLinks label={t('company_partners')} />
					<FooterLinks label={t('faq')} link={"/faq"} />
					<FooterLinks label={t('contact')} link={"/kontaktet"} />
				</div>
			</div>
			<div className="mb-1 py-4">
				<div className="flex flex-col sm:flex-row w-full items-center space-y-1 ">
					<p className="shrink-0 text-primary">
						&#169; 2023 HS HQ All rights reserved.
					</p>
					<div className="flex flex-row justify-center sm:justify-end sm:mr-2 w-full space-x-11">
						<p className="text-primary">Terms of use</p>
						<p className="text-primary">Privacy Policy</p>
					</div>
				</div>
			</div>
		</footer>
	);
}
