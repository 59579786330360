import React from "react";

function Button({ label, onClick, type = "primary", className = "" }) {
  const typeClasses =
    type === "primary"
      ? "bg-primary text-white px-16"
      : "bg-white text-primary px-12 border border-primary text-xs md:text-xl";
  return (
    <button
      onClick={onClick}
      className={`rounded-full py-2 ${typeClasses} ${className}`}
    >
      {label}
    </button>
  );
}

export default Button;
