import React from "react";
import { useTranslation } from 'react-i18next';
import Button from "../../components/Button";
import hat from "../../images/Hat.jpg";
import fullstack_summer from "../../images/fullstack_summer.jpeg";
import WhyUsSection from "./components/WhyUsSection";
import Testimonials from "./components/Testimonials";
import Curriculum from "./components/Curriculum";
import Partners from "./components/Partners";
import Map from "./components/Map";
import Methodology from "./components/Methodology";
import karli from "../../images/karli.png";
import "./index.css";

function Homepage() {
	const { t } = useTranslation();
	return (
		<div className="">
			<div id="ktu" className="mb-10">
				<img
					src={karli}
					alt="karl"
					className="hidden lg:block absolute right-0 h-[850px] z-1 horse"
				></img>
				<section className="min-h-[450px] py-7 bg-secondary">
					<div className="md:ml-20">
						<div className="my-3 py-4">
							<h4 className="sm:text-5xl text-3xl text-center text-primary sm:text-start md:text-5xl md:max-w-[700px]">{t('chooseSuccess')}</h4>
							<h1 className="sm:text-5xl text-3xl text-center sm:text-start md:text-8xl md:max-w-[900px]">
								{t('becomeDeveloperInHbt')}
							</h1>
							<span className="mt-4 pt-3 block text-2xl mb-2 text-primary text-center sm:text-start">
								{t('defineFuture')}
							</span>
						</div>
						<div className="flex flex-col sm:flex-row justify-center items-center sm:justify-start sm:items-start">
							<div className="my-2 sm:mx-2">
								<a href="https://apply.holbertonschool.com/" target="blank">
									<Button label={t('applyNow')} type="primary" />
								</a>
							</div>
							<div className="my-2 sm:mx-2">
								<a href="https://holbertonsite.vercel.app/programet" target="blank">
									<Button label={t('learnMore')} type="secondary" />
								</a>
							</div>
						</div>
					</div>
				</section>

				<div>
					<div
						className="hidden sm:block"
						style={{
							backgroundImage: `linear-gradient(to bottom, rgba(241, 242, 242, 0.42), rgba(225, 29, 63, 0.47)),url("${hat}")`,
							height: 600,
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}
					></div>
				</div>
				<div>
					<a href="/news_event/1">
						<img src={fullstack_summer} width={"100%"} alt="event" />
					</a>
				</div>

			</div>
			<div className="hidden md:block md:h-[70px]"></div>

			<section id="WhyUs">
				<WhyUsSection />
			</section>
			<div className="hidden md:block md:h-[70px]"></div>
			<section id="Curriculum">
				<Curriculum />
			</section>
			<div className="hidden md:block md:h-[70px]"></div>
			<section id="Methodology">
				<Methodology />
			</section>
			<div className="h-[40px] md:h-[100px]"></div>
			<section id="Testimonials">
				<Testimonials />
			</section>
			<section id="Partners">
				<Partners />
			</section>
			<div className="hidden md:block md:h-[60px]"></div>
			<section id="Map">
				<Map />
			</section>
			<div className="hidden md:block md:h-[100px]"></div>
		</div>
	);
}

export default Homepage;
