import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Question from "./components/question";
import Button from "../../components/Button";


export default function Faq() {
  const { t } = useTranslation();

  const data = [
    {
      question: t('whyToChooseHbtn'),
      answer: (
        <div>
          {t('hbtnPurpose')}
        </div>
      ),
    },
    {
      question: t('noPriorKnowledge'),
      answer: (
        <div>
          {t('noPriorAnswer')}
        </div>
      ),
    },
    {
      question: t('comeFromDiff'),
      answer: (
        <div>
          {t('comeFromDiffAnswer')}
        </div>
      ),
    },
    {
      question: t('howToRegister'),
      answer: (
        <div>
          {t('howToRegisterAnswer')}
        </div>
      ),
    },
    {
      question: t('tuitionFee'),
      answer: (
        <div>
          {t('tuitionFeeAnswer')}
        </div>
      ),
    },
    {
      question: t('howLongSch'),
      answer: (
        <div>
          {t('howLongSchAns')}
        </div>
      ),
    },
    {
      question: t('whatIamLearning'),
      answer: (
        <div>
          {t('whatIamLearningAns')}
        </div>
      ),
    },
    {
      question: t('whereGetJob'),
      answer: (
        <div>
          {t('whereGetJobAns')}
        </div>
      ),
    },
  ];

  const [popup, setPopup] = useState(null);
  const faqPopup = () => {
    return (
      <div className="fixed text-center card h-[120px] w-[250px] bg-secondary text-primary border-2 border-primary border-solid rounded-2xl text-xl left-20">
        <div className="relative">
          <button className="absolute right-2" onClick={() => setPopup(null)}>
            x
          </button>
          <h5 className="p-4">{t('haveMoreQuestion')}</h5>
          <a href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1LRLT6cdAduWbvRQySvG7TVEjf9Pqtn1Oh-tqDdv4S7lGaS7JOBwJW5W7jyjamgsAuhOmGWi7V?gv=true">
            <Button label={t('bookAppointment')} className="px-8 py-1" />
          </a>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setPopup(faqPopup());
    }, 1500);
  }, []);

  return (
    <div className="my-[100px] relative">
      {popup}
      <div className="flex flex-col items-center mb-10 space-y-10">
        <p className="text-5xl text-primary text-center max-w-xs">
          Frequently Asked <span className="text-black">Questions</span>
        </p>
        <div className="flex flex-col space-y-5">
          {data.map((o, index) => {
            return <Question qa={o} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
