import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import hlogo from "../images/logo.png";


const NavItem = ({ label, link, isLast }) => {
	const style = () => {
		return isLast ? "border-r-0" : "border-r-2";
	};
	return (
		<a
			href={link}
			className={`mx-auto md:mx-0 md:px-4 border-gray-300 text-xl font-extralight md:${style()}`}
		>
			{label}
		</a>
	);
};

export default function Header() {
	const { t, i18n } = useTranslation();

	const [modalOpen, setModalOpen] = useState(false);

	const toggleModal = () => {
		setModalOpen(!modalOpen);
	};

	const handleLanguage = (e) => {
		i18n.changeLanguage(e.target.value);
		localStorage.setItem('language', e.target.value)
	}


	return (
		<header className="relative">
			<div className="flex flex-row justify-between">
				<a href="/">
					<img
						src={hlogo}
						alt="Logo"
						style={{ height: 60 }}
						className="w-[200px] mt-6 mb-3 flex-grow-0 ml-4 lg:ml-16"
					></img>
				</a>

				<nav className="hidden  md:flex relative w-full items-center justify-end">
					<NavItem label={t('about-us')} link="/about-us" />
					<NavItem label={t('programs')} link="/programet" />
					<NavItem label={t('methodology')} link="/metodologjia" />
					<NavItem label={t('students')} link="/studentet" />
					<NavItem label={t('news_event')} link='/news_event' />
					<NavItem label={t('contact')} link="/kontaktet" />
					<NavItem label={t('faq')} link="/faq" isLast />
					<select name="selectLanguage" value={i18n.language} onChange={handleLanguage}>
						<option value="en">English</option>
						<option value="sq">Albania</option>
					</select>
				</nav>
				<div className="flex md:hidden my-auto p-2 mr-5">
					<div className="space-y-2" onClick={toggleModal}>
						<span className="block w-8 h-0.5 bg-gray-600 animate-pulse"></span>
						<span className="block w-8 h-0.5 bg-gray-600 animate-pulse"></span>
						<span className="block w-8 h-0.5 bg-gray-600 animate-pulse"></span>
					</div>
				</div>
			</div>
			{modalOpen ? (
				<div className="absolute w-full top-full left-0">
					<nav className="flex flex-col space-y-3 bg-white py-3">
						<NavItem label={t('about-us')} link="/about-us" />
						<NavItem label={t('programs')} link="/programet" />
						<NavItem label={t('methodology')} link="/metodologjia" />
						<NavItem label={t('students')} link="/studentet" />
						<NavItem label={t('news_event')} link='/news_event' />
						<NavItem label={t('contact')} link="/kontaktet" />
						<NavItem label={t('faq')} link="/faq" isLast />
						<select name="selectLanguage" defaultValue={"sq"} onChange={handleLanguage} style={{ width: "120px", margin: "0 auto" }}>
							<option value="en">English</option>
							<option value="sq">Albania</option>
						</select>
					</nav>
				</div>
			) : null}
		</header>
	);
}
