import React from 'react';
import Footer from "./components/Footer";
import Header from "./components/Header";
import Homepage from "./pages/home";
import About from "./pages/about";
import { MethodologyPage } from "./pages/Methodology";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contacts from "./pages/contacts";
import Faq from "./pages/Faq";
import Programs from "./pages/Programs";
import Students from "./pages/Students";
import Blog from "./pages/Blog/";
import AboutUs from "./pages/about_us/about_us";
import SingleEvent from "./pages/SingleEvent";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

function App() {
	return (
		<div className="h-screen" style={{ fontFamily: "Aktiv Grotesk" }}>
			<Header />
			<main>
				<I18nextProvider i18n={i18n}>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<Homepage />} />
							<Route path="/home" element={<Homepage />} />
							<Route path="/about" element={<About />} />
							<Route path="/kontaktet" element={<Contacts />} />
							<Route path="/metodologjia" element={<MethodologyPage />} />
							<Route path="/faq" element={<Faq />} />
							<Route path="/about-us" element={<AboutUs />} />
							<Route path="/studentet" element={<Students />} />
							<Route path="/news_event" element={<Blog />} />
							<Route path="/news_event/:id" element={<SingleEvent />} />
							<Route path="/programet" element={<Programs />} />
						</Routes>
					</BrowserRouter>
				</I18nextProvider>
			</main>
			<Footer />
		</div>
	);
}

export default App;
