import React from 'react';
import "./index.css";
import { useTranslation } from 'react-i18next';
import MethodologyCard from "./components/MethodologyCard";
import group_learning from "../../images/grouplearning.png";
import learnhowto from "../../images/learnhowto.png";
import practicalexp from "../../images/practicalexp.png";
import projectbased from "../../images/projectbased.png";

import { useEffect, useState } from "react";

function MethodologyPage() {
  const { t } = useTranslation();
  const data = [
    {
      title: t('project-based'),
      content: (
        <div>
          <h2 className="text-xl font-semibold">{t('project-based-one')}</h2>
          < br />
          <p className="text-gray-700">
            {t('project-based-two')}
          </p>
          <br />
          <p className="text-gray-700">
            {t('project-based-three')}
          </p>
        </div>
      ),

      image: practicalexp,
    },
    {
      title: t('project-based-four'),
      content: (
        <div>
          <h2 className="text-xl font-semibold">{t('project-based-five')}</h2>
          <br /> <br />
          <p className="text-gray-700">
            {t('project-based-six')}
          </p>
        </div>
      ),
      image: projectbased,
    },
    {
      title: t('group-learning'),
      content: (
        <div>
          <h2 className="text-xl font-semibold">{t('group-learning-one')}</h2>
          <br /> <br />
          <p className="text-gray-700">
            {t('group-learning-two')}
          </p>
        </div>
      ),
      image: group_learning,
    },
    {
      title: t('indipendent-learning'),
      content: (
        <div>
          <h2 className="text-xl font-semibold">{t('indipendent-learning-one')}</h2>
          <br /> <br />
          <p className="text-gray-700">
            {t('indipendent-learning-two')}
          </p>
        </div>
      ),
      image: learnhowto,
    },
  ];

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderedCard = data.map((content, index) => {
    const isInView =
      window.innerHeight + scrollY > (index + 1) * 500 - 100 + index * 10;
    const animation = isInView
      ? index % 2 === 0
        ? "slide-left 1.0s ease-in-out"
        : "slide-right 1.0s ease-in-out"
      : "";
    return index % 2 === 0 ? (
      <MethodologyCard
        title={content.title}
        content={content.content}
        image={content.image}
        style={{ animation }}
      />
    ) : (
      <MethodologyCard
        title={content.title}
        content={content.content}
        image={content.image}
        style={{ animation }}
        reversed
      />
    );
  });

  return (
    <div className="flex flex-col justify-center mt-16">
      <div className="flex flex-col text-center space-y-8 " id="text">
        <h2 className="text-primary text-5xl hbt-method-text" style={{ fontWeight: "bold" }}>{t('hbt-methodology')}</h2>
      </div>
      <div className="flex flex-col pt-12 items-center pb-8 ">
        {renderedCard}
      </div>
    </div>
  );
}

export { MethodologyPage };
