import React from "react";
import { useTranslation } from 'react-i18next';
import TestimonialCard from "./TestimonialCard";
import "../styles/styles.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useGetTestimonials from "../../../useGetTestimonials";

function Testimonials() {
  const { t } = useTranslation();
  const peopleTestimonials = useGetTestimonials("testimonial");

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1440 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1439, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: `url("https://uploads-ssl.webflow.com/6105315644a26f77912a1ada/610599c2a1cb34f002714d61_waves.svg")`,
          height: 100,
          transform: "rotate(180deg)",
        }}
      ></div>
      <div className="flex flex-col space-y-10 bg-secondary h-[500px]">
        <h3 className="mx-auto text-5xl py-5 home-hbt-txt text-center">{t('hbt-what-say')}</h3>
        <Carousel
          swipeable={true}
          draggable={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          containerClass="carousel-container"
          partialVisible={true}
          focusOnSelect={true}
          itemClass="lg:px-10 px-[35px]"
          autoPlay={true}
          autoPlaySpeed={4000}
        >
          {peopleTestimonials.map((person, index) => {
            return (
              <div key={index}>
                <TestimonialCard person={person} key={index} />
              </div>
            );
          })}
        </Carousel>
      </div>
      <div
        style={{
          backgroundImage: `url("https://uploads-ssl.webflow.com/6105315644a26f77912a1ada/610599c2a1cb34f002714d61_waves.svg")`,
          height: 100,
          // backgroundSize: '200% 100px',
        }}
        className="bg-primary"
      ></div>
    </div>
  );
}

export default Testimonials;
